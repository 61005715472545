import ScrollMagic from 'scrollmagic';

let beeCount = 0;

// Random int function taken from MSDN
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function xToPercent(x){
    var percent = (x / window.innerWidth) * 100 + '%';
    return percent;  
}

function yToPercent(y){
    var percent = (y / window.innerHeight) * 100 + '%';
    return percent;  
}

function createBee(x, y){
    var leftPos = xToPercent(x);
    var topPos = yToPercent(y);
    
    var flyZone = document.createElement('div');
    flyZone.classList.add('fly-zone');
    flyZone.style.top = topPos;
    flyZone.style.left = leftPos;
    flyZone.style.width = getRandomInt(30, 70) + '%';
    
    var beeWrapper = document.createElement('div');
    beeWrapper.classList.add('bee-wrapper');
    // beeWrapper.style.animationDuration =  getRandomInt(1500, 4000) + 'ms';
    beeWrapper.style.animationDuration =  getRandomInt(16000, 21000) + 'ms';
    flyZone.appendChild(beeWrapper);
    
    var beeBody = document.createElement('div');
    beeBody.classList.add('bee');
    beeWrapper.appendChild(beeBody);  
    
    var wings = document.createElement('div');
    wings.classList.add('wings');
    beeBody.appendChild(wings);
    
    return flyZone;
}

function addBeeToCanvas(x,y){
    if (beeCount > 2) {
        return;
    }

    var canvas = document.querySelector('.bee-canvas');

    if ( ! canvas ) {
        return;
    }
    
    var bee = createBee(x, y);
    canvas.appendChild(bee);
    beeCount++;

    setTimeout( function(){
        bee.classList.add('expanded');
    }, 100)  
}

function moveBeesOffCanvas(){
    var bees = document.querySelectorAll('.bee-fly-zone');
    for(var i = 0; i < bees.length; i++){
        var bee = bees[i];
        bee.classList.add('remove');
    }  
}

document.addEventListener('click', function(e){
    if(e.target != undefined){
        var x = e.clientX;
        var y = e.clientY;
        // console.log(e);
        addBeeToCanvas(x,y);
    }
});

//keep viewport from bouncing around on mobile
document.addEventListener('touchmove', function(e){ 
    e.preventDefault(); 
});

const initBeeOnScroll = () => {
    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({triggerElement: ".add-bee-section", duration: 200})
        .addTo(controller)
        .on('start end', function (e) {
            var x = getRandomInt(0, window.outerWidth);
            var y = getRandomInt(0, window.outerHeight);
            addBeeToCanvas(x,y);
        });


}
document.addEventListener('BarbaContentLoaded', initBeeOnScroll);
document.addEventListener('DOMContentLoaded', initBeeOnScroll);
