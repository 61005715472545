document.addEventListener("DOMContentLoaded", function() {

    [].forEach.call(document.querySelectorAll('[data-toggle]'), function (button, index) {
        const target = document.getElementById( button.dataset.toggle );

        if ( ! target ) {
            return;
        }

        button.addEventListener('click', function (e) {
            target.classList.toggle('is-open');

            if ( target.classList.contains('is-open') ) {
                button.setAttribute('aria-expanded', true);
                document.body.classList.add( 'is-menu-open' );
            } else {
                button.removeAttribute('aria-expanded');
                document.body.classList.remove( 'is-menu-open' );
            }
        });
    });


    // Submenu collapse on mobile
    [].forEach.call(document.querySelectorAll('#main-nav li.menu-item-has-children > a'), function (anchor, index) {
        anchor.addEventListener('click', function (e) {
            const parent = anchor.parentElement;

            if ( window.outerWidth >= 1024 ) {
                return;
            }

            e.preventDefault();

            if ( ! parent.hasAttribute('aria-expanded') ) {
                parent.setAttribute('aria-expanded', true);
            } else {
                parent.removeAttribute('aria-expanded');
            }
        });
    });

    // Close on click in main nav
    [].forEach.call(document.querySelectorAll('#main-nav li a'), function (anchor, index) {
        anchor.addEventListener('click', function (e) {
            const mainNav = document.querySelector('#main-nav');

            [].forEach.call(document.querySelectorAll('[data-toggle="#main-nav"]'), function (button, index) {
                button.removeAttribute('aria-expanded');
            });

            mainNav.classList.remove('is-open');
            document.body.classList.remove('is-menu-open');
        });
    });

    // Close on ESC
    document.addEventListener('keydown', (event) => {
        if ( event.key === 'Escape' ) {
            const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
            if (isNotCombinedKey) {
                // Close main nav
                document.querySelector('#main-nav').classList.remove('is-open');
                document.body.classList.remove( 'is-menu-open' );
            }
        }
    });

});
