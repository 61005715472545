import barba from '@barba/core';
import { gsap } from 'gsap';
import { getOffsetTop, scrollToHash } from './hash-anchors';

barba.init({
    transitions: [{
        name: 'opacity-transition',
        leave(data) {
            gsap.to('h1', {
                x: -10,
            });

            return gsap.to(data.current.container, {
                opacity: 0
            });
        },
        enter(data) {
            return gsap.from(data.next.container, {
                opacity: 0
            });
        }
    }]
});

barba.hooks.beforeLeave((data) => {
    document.getElementById('main-nav').classList.remove('is-open');
    document.querySelector('[data-toggle="main-nav"]').removeAttribute('aria-expanded');
    document.body.classList.remove( 'is-menu-open' );

    document.querySelectorAll('#main-nav li').forEach( el => el.classList.remove('current-menu-item') );
    data.trigger.parentElement.classList.add('current-menu-item');
});

barba.hooks.enter((data) => {
    const event = new Event('BarbaContentLoaded');
    document.dispatchEvent(event);

    if (wpforms in window) {
        window.wpforms.init();
    }

    // If hash in url, scroll to hash
    if ( document.location.hash ) {
        scrollToHash( document.location.hash );
    }
});

barba.hooks.after((data) => {
    // If hash in url, scroll to hash
    if ( document.location.hash ) {
        scrollToHash( document.location.hash );
    }
});
