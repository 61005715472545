import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import ScrollMagic from 'scrollmagic';

gsap.registerPlugin(ScrollTrigger)

const initAnimations =() => {
    // const controller = new ScrollMagic.Controller();

    document.querySelectorAll(".bloem").forEach( el => {
        gsap.to(el, {
            x: -25,
            y: window.innerHeight / 3,
            ease: "none",
            scrollTrigger: {
                trigger: el,
                scrub: true,
                start: "top bottom",
                end: "+=200%"
            }
        })
    })

    document.querySelectorAll(".bijenkorf").forEach( el => {
        gsap.to(el, {
            x: 50,
            y: 50,
            ease: "none",
            scrollTrigger: {
                trigger: el,
                scrub: true,
                start: "top bottom",
                end: "+=150%"
            }
        })
    })


    gsap.utils.toArray(".section").forEach(section => {
        // gsap.from(section.querySelectorAll(".bijenkorf"), {
        //     scrollTrigger: section,
        //     autoAlpha: 0,
        //     x: -300,
        //     duration: 1,
        //     stagger: 0.33
        // });
    });

    // gsap.utils.toArray(".section").forEach( section => {

    //     const scene = new ScrollMagic.Scene({
    //         triggerElement: section,
    //     })
    //         .addTo(controller)
    //         .on('enter', function (e) {
    //             // console.log('enter', e);
    //             gsap.from(section.querySelectorAll(".wp-block-image:not(.bijenkorf)"), {
    //                 autoAlpha: 0,
    //                 y: 25,
    //                 duration: 1,
    //                 stagger: 0.33
    //             });
    //         })
            
    //         .on('leave', function (e) {
    //             console.log('leave', e);
    //             gsap.to(section.querySelectorAll(".wp-block-image:not(.bijenkorf)"), {
    //                 autoAlpha: 0,
    //                 opacity: 0,
    //                 duration: 1,
    //                 stagger: 0.33
    //             });
    //         });

    // })

    // const scene = new ScrollMagic.Scene({triggerElement: "section", duration: 200})
    //     .addTo(controller)
    //     // .addIndicators() // add indicators (requires plugin)
    //     .on("update", function (e) {
    //         $("#scrollDirection").text(e.target.controller().info("scrollDirection"));
    //     })
    //     .on("enter leave", function (e) {
    //         $("#state").text(e.type == "enter" ? "inside" : "outside");
    //     })
    //     .on("start end", function (e) {
    //         $("#lastHit").text(e.type == "start" ? "top" : "bottom");
    //     })
    //     .on("progress", function (e) {
    //         $("#progress").text(e.progress.toFixed(3));
    //     });


    if ("IntersectionObserver" in window) {
        const delay = 333;
		let sectionObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
                    entry.target.querySelectorAll('.fx').forEach((image, index) => {
                        setTimeout( () => image.classList.add('animated'), delay * index )
                    })
				} else {
                    entry.target.querySelectorAll('.fx').forEach(image => image.classList.remove('animated'))
                }
			});
		});

        document.querySelectorAll('h1, .wp-block-image:not(.bijenkorf):not(.no-fx)').forEach( image => image.classList.add('fx') );

		document.querySelectorAll('.section').forEach(function(section) {
			sectionObserver.observe(section);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}

    setTimeout( () => document.querySelector('.loader-wrapper').classList.add('loaded'), 999 );

   
    /*
     * Button in-view
     */
    if ("IntersectionObserver" in window) {
		let buttonObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
                    entry.target.classList.add('in-view')
				} else {
                    entry.target.classList.remove('in-view')
                }
			});
		});

		document.querySelectorAll('.wp-block-button__link, .wpforms-submit').forEach(function(button) {
			buttonObserver.observe(button);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}
}

document.addEventListener('DOMContentLoaded', initAnimations);
document.addEventListener('BarbaContentLoaded', initAnimations);
